<template>
    <div class="aboutus">
        <div class="main-limiter margin-bottom-6">
            <h1 class="margin-bottom-0">Meet the AIREV Team</h1>
            <p class="text-center text-bold margin-top-0a">AI REV is a company with a goal to <strong>reduce the average work hours of humans from 40h to 20h</strong>. <br>
                We achieve it by augmenting companies with AI powered efficiency improvements.</p>
        </div>



        <div class="main-limiter">
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="margin-top-4">
                        <DoroImage :src="peopleMarek" max-width="220px"></DoroImage>
                        <h2 class="text-center margin-top-0a">Marek Bardoński</h2>
                    </div>
                </el-col>
                <el-col :span="16">
                    <h2 class="margin-top-0">Managing partner</h2>
                    <p>
                        Natural Language Processing and Predictive Analytics expert with over 13 years of commercial experience. Recognized by NASA, Harvard and multiple international corporations (NVIDIA, Microsoft, Duff & Phelps, Novartis, Financial Times, Bloomberg)
                    </p>
                    <p>
                        Before starting a consulting career, Marek was working as a Senior Deep Learning Research Engineer at NVIDIA Switzerland.
                    </p>
                    <div class="margin-top-1a margin-bottom-1a">
                        <DoroImage :src="logoNvidia" max-width="3em" simple inline max-height="3em" square margin="0 1em 0 0"></DoroImage>
                        <DoroImage :src="logoMicrosoft" max-width="3em" simple inline max-height="3em" square margin="0 1em 0 0"></DoroImage>
                        <DoroImage :src="logoKroll" max-width="5em" simple inline max-height="3em" margin="0 1em 0 0"></DoroImage>
                        <DoroImage :src="logoNasa" max-width="3em" simple inline max-height="3em" square margin="0 1em 0 0"></DoroImage>
                        <DoroImage :src="logoHarvard" max-width="3em" simple inline max-height="3em" margin="0 1em 0 0"></DoroImage>
                        <DoroImage :src="logoSbit" max-width="5em" simple inline max-height="3em" margin="0 1em 0 0"></DoroImage>
                    </div>
                    <div class="margin-top-1a">
                        <URL to="https://www.linkedin.com/in/marek-bardonski/"><DoroButton>Find out more</DoroButton></URL>
                    </div>
                </el-col>
            </el-row>
        </div>

<!--        <div class="main-limiter margin-top-5 margin-bottom-5">-->
<!--            <h2 class="margin-top-0 margin-bottom-1 text-center">Board of Directors</h2>-->
<!--            <el-row :gutter="20">-->
<!--                <el-col :span="8">-->
<!--                    <div>-->
<!--                        <DoroImage src="https://airev.us/wp-content/uploads/2020/03/Screen-Shot-2020-01-17-at-00.18.38.png" max-width="220px"></DoroImage>-->
<!--                        <h2 class="text-center margin-top-0a">Karolina Latus</h2>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--                <el-col :span="8">-->
<!--                    <div>-->
<!--                        <DoroImage src="https://airev.us/wp-content/uploads/2020/03/Screen-Shot-2020-01-17-at-00.18.38.png" max-width="220px"></DoroImage>-->
<!--                        <h2 class="text-center margin-top-0a">Dawid Adach</h2>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--                <el-col :span="8">-->
<!--                    <div>-->
<!--                        <DoroImage src="https://airev.us/wp-content/uploads/2020/03/Screen-Shot-2020-01-17-at-00.18.38.png" max-width="220px"></DoroImage>-->
<!--                        <h2 class="text-center margin-top-0a">Ritchie Ng</h2>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--        </div>-->

        <div class="margin-top-5 margin-bottom-5" style="background: linear-gradient(#fff, rgba(236,239,247,0.5));">
            <div class="main-limiter" style="padding-bottom:3em;">
                <h2 class="margin-top-0 margin-bottom-1 text-center">We've got it all covered</h2>
                <p class="text-center">We have extensive experience in large-scale systems and handling sensitive data and work in accordance with</p>
                <div class="text-center" style="background: white;box-shadow:-5px 19px 26px rgba(222,222,222,0.79);border-radius: 1em;padding:40px">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <DoroImage :src="iconBrain" max-width="80px"></DoroImage>
                            GDPR
                        </el-col>
                        <el-col :span="6">
                            <DoroImage :src="iconCircuit" max-width="80px"></DoroImage>
                            HIPAA
                        </el-col>
                        <el-col :span="6">
                            <DoroImage :src="iconPassword" max-width="80px"></DoroImage>
                            PCI-DSS
                        </el-col>
                        <el-col :span="6">
                            <DoroImage :src="iconEnrichment" max-width="80px"></DoroImage>
                            CCPA
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <div class="main-limiter margin-top-5 margin-bottom-5">
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="margin-top-4">
                        <DoroImage :src="peopleBill" max-width="220px"></DoroImage>
                        <h2 class="text-center margin-top-0a">Bill Scuba</h2>
                    </div>
                </el-col>
                <el-col :span="16">
                    <h2 class="margin-top-0">Partner - USA, West Coast</h2>
                    <p>
						Bill is a senior data scientist and team leader with over 20 years of experience. He has worked on projects covering a wide range of fields, including healthcare, financial services, physics, and oceanography. He is a graduate of physics at the University of California in Los Angeles, where his journey with science has technically started. His mind was preoccupied with technical innovations, unconventional inventions, and the world of science from an early age. Working in the <strong>Scripps Institution of Oceanography</strong> for more than four years, showed that he was able to manage not only himself, but also the team and the budget as well. Less than four years after the end of his first job, he was already the owner of <strong>Offshore Real Estate International</strong> - a company helping people buy real estate outside their native country using data analytics. Then, in February 2019, basing on over a decade of a successful experience in different fields of science, he founded his second business specializing in Artificial Intelligence Consulting.
                    </p>
                    <div class="margin-top-1a">
						<router-link to="/contact-us"><DoroButton>Find out more</DoroButton></router-link>
                    </div>
                </el-col>
            </el-row>
        </div>

      <div class="main-limiter margin-top-5 margin-bottom-5">
        <el-row :gutter="20">

          <el-col :span="16">
            <h2 class="margin-top-0">Partner - USA, East Coast</h2>
            <p>
              I had the privilege of working for many years at several of the great centers of research and development of AI, Natural Language Processing (NPL) and Machine Learning: two spin offs of Yale AI Department, Bell Labs, IBM TJ Watson Labs, and now at Experfy, a spin-off of the Harvard Incubation Lab.<br>
              <br>
              At have recently received, in partnership with Oracle, the 2018 Innovation Challenge Award from The Guardian Life Insurance Company.<br>
              <br>
              I have also been Chief Enterprise Architect for over twenty years at several major corporations, including Citigroup, Ally Bank, Harcourt Education, Oracle, among others. Lately I have been architecting large AI systems in the cloud.<br>
            </p>
            <div class="margin-top-1a">
              <router-link to="/contact-us"><DoroButton>Find out more</DoroButton></router-link>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="margin-top-4">
              <DoroImage :src="peopleJoaquin" max-width="220px"></DoroImage>
              <h2 class="text-center margin-top-0a">Joaquin Marques</h2>
            </div>
          </el-col>
        </el-row>
      </div>
        <!--<div class="main-limiter margin-top-5 margin-bottom-5">
            <el-row :gutter="20">
                <el-col :span="16">
                    <h2 class="margin-top-0">Partner - Germany</h2>
                    <p>
                        Mateusz is a machine learning engineer who gained both commercial and scientific experience working for the most renowned institutions. Not only has worked on research machine learning and HPC projects in <strong>CERN</strong> and <strong>Max Planck Institute</strong>, on the other hand he gained commercial experience while working as a data scientist for <strong>McKinsey & Company</strong>. He holds a Master's degree in computer science from the University of Warsaw. In the past, he has implemented production ML systems that use unsupervised and supervised techniques including deep neural networks. He worked on text mining, NLP and time series problems for clients from various markets, including telecoms, e-commerce clients and social media portals. In addition to his extensive machine learning experience, Mateusz is a skillful programmer with four years of experience in full stack web development.
                    </p>
                    <div class="margin-top-1a">
						<router-link to="/contact-us"><DoroButton>Find out more</DoroButton></router-link>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="margin-top-4">
                        <DoroImage :src="peopleMateusz" max-width="220px"></DoroImage>
                        <h2 class="text-center margin-top-0a">Mateusz Susik</h2>
                    </div>
                </el-col>
            </el-row>
        </div>-->

<!--        <div class="main-limiter margin-top-5 margin-bottom-5">-->
<!--            <el-row :gutter="20">-->
<!--                <el-col :span="8">-->
<!--                    <div class="margin-top-4">-->
<!--                        <DoroImage :src="peoplePatryk" max-width="220px"></DoroImage>-->
<!--                        <h2 class="text-center margin-top-0a">Patryk Binkowski</h2>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--                <el-col :span="16">-->
<!--                    <h2 class="margin-top-0">Partner - Poland</h2>-->
<!--                    <p>-->
<!--						Patryk is a scientist and researcher with passion. Specializing in natural language processing (text and speech) and digital signal processing, music, and a bit in computer vision. Member of the <strong>Language Technology Group G4.19</strong> Wroclaw University of Technology. Lover of statistics, probability theory, R, and Python. Data, scientific papers, and conferences lover. Madman of algorithmic and hardware performance optimizations. Enthusiast of High-Performance Computing (HPC), GRID systems, and supercomputing solutions. Winter sports fan, mainly skiing.-->
<!--                    </p>-->
<!--                    <div class="margin-top-1a">-->
<!--                        <URL to="https://calendly.com/marek-bardonski/15min"><DoroButton>Find out more</DoroButton></URL>-->
<!--                    </div>-->
<!--                </el-col>-->
<!--            </el-row>-->
<!--        </div>-->

        <div class="main-limiter margin-top-5 margin-bottom-5">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="margin-top-4">
                <DoroImage :src="peopleBastiaan" max-width="220px"></DoroImage>
                <h2 class="text-center margin-top-0a">Bastiaan Bergman</h2>
              </div>
            </el-col>
            <el-col :span="16">
              <h2 class="margin-top-0">Partner - Europe, Netherlands</h2>
              <p>


                Machine Learning Engineer with over 10 years of commercial experience. Before AI REV worked for Seagate, IBM and Western Digital. Lastly, at Atomwise, he applied machine vision and deep learning to whole structural images of molecules and human proteins, predicting new drugs for unresolved diseases.
                </p><p>
                Physicist by education, with broad experience progressing from experimental physics through large scale manufacturing to machine learning. Bastiaan won a silver medal in a deep learning contest by the United States Department of Homeland Security, on airport microwave scans of passengers. Love to learn new ways, design better tools and make it work. Intrigued by how computers will take over the last remaining human tasks.

              </p>
              <div class="margin-top-1a">
                <router-link to="/contact-us"><DoroButton>Find out more</DoroButton></router-link>
              </div>
            </el-col>

          </el-row>
        </div>

      <div class="main-limiter margin-top-5 margin-bottom-5">
        <el-row :gutter="20">
          <el-col :span="16">
            <h2 class="margin-top-0">Partner and Chief Technology Officer</h2>
            <p>
              Matt is a highly skilled engineer and entrepreneur with over <b>10 years of commercial experience</b> in various industries, including content creation, health tech, fintech, SaaS, and e-commerce. He has a strong track record of helping companies accelerate their technology processes and overcome their most pressing challenges.
              </p><p>
              In addition to his work, Matt also provides strategic guidance to startups in the technology industry, assisting with the development of products, services, and processes. He is also actively working on an AI-powered search engine and discovery platform, aimed at helping users easily access and discover high-quality content on the internet.
              </p><p>
              Motivated by a belief that technology has the power to improve people's lives, Matt is dedicated to empowering individuals and organizations to succeed. His personal goal is to create opportunities for anyone and everyone to achieve their full potential.
          </p>
            <div class="margin-top-1a">
              <router-link to="/contact-us"><DoroButton>Find out more</DoroButton></router-link>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="margin-top-4">
              <DoroImage :src="peopleMatt" max-width="220px"></DoroImage>
              <h2 class="text-center margin-top-0a">Matt Doroszko</h2>
            </div>
          </el-col>

        </el-row>
      </div>





      <div class="main-limiter margin-top-5 margin-bottom-5">
            <el-row :gutter="20">
              <el-col :span="24">
                  <h2 class="margin-top-0 center">Engineering team </h2>
                  <p class="just">
                      We hired the best engineers from different continents (Americas, Europe, and Asia), specializing in Machine Learning, Natural Language Processing, Predictive Analytics, Computer Vision, and other fields. They have vast commercial experience and develop themselves in a scientific field.
                  </p>
                  <p class="just">
                      Members of our engineering team have experience in developing solutions for industries like Sales, Marketing, Finance, Healthcare, Legal, and many others. They have been working for the companies like IBM, Intel, Bosch, Nokia, Opera or Accenture.
                  </p>
                  <p class="just">
                      We are certain that basing our projects on fresh research leads to maximizing the efficiency of our work. That is why many of our engineers are working towards a Ph.D. or are already on the highest academic level.
                  </p>
              </el-col>
            </el-row>
        </div>
    </div>

</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import DoroImage from '@/components/DoroImage';
    import DoroButton from '@/components/DoroButton';
    import URL from '@/components/URL';

    import peopleBill from '@/assets/people/Bill.jpg';
    import peopleMarek from '@/assets/people/Marek.jpg';
    import peopleBastiaan from '@/assets/people/Bastiaan.jpg';
    import peopleDavid from '@/assets/people/David.png';
    import peopleMateusz from '@/assets/people/Mateusz.jpg';
    import peoplePatryk from '@/assets/people/Patryk.jpg';
    import peopleJoaquin from '@/assets/people/Joaquin.jpg';
    import peopleIwona from '@/assets/people/iwona.jpg';
    import peopleMatt from '@/assets/people/matt.jpg';

    import logoNvidia from '@/assets/logos/nvidia-logo.png';
    import logoMicrosoft from '@/assets/logos/microsoft-logo.png';
    import logoKroll from '@/assets/logos/kroll-logo.png';
    import logoNasa from '@/assets/logos/nasa-logo.png';
    import logoHarvard from '@/assets/logos/harvard-logo.png';
    import logoSbit from '@/assets/logos/Sbit-logo.png';

    import iconBrain from '@/assets/pages/team/brain.png';
	import iconCircuit from '@/assets/pages/team/circuit.png';
	import iconEnrichment from '@/assets/pages/team/enrichment.png';
	import iconPassword from '@/assets/pages/team/password.png';

    @Component({
        components: {
            DoroImage,
            DoroButton,
            URL,
        },
        metaInfo() {
            return {
                title: 'About Us',
            }
        }
    })
    export default class AboutUs extends Vue {
        get logoNvidia() { return logoNvidia; }
        get logoMicrosoft() { return logoMicrosoft; }
        get logoKroll() { return logoKroll; }
        get logoNasa() { return logoNasa; }
        get logoHarvard() { return logoHarvard; }
        get logoSbit() { return logoSbit; }

        get peopleBill() { return peopleBill; }
        get peopleMarek() { return peopleMarek; }
        get peopleMateusz() { return peopleMateusz; }
        get peoplePatryk() { return peoplePatryk; }
        get peopleDavid() { return peopleDavid; }
        get peopleBastiaan() { return peopleBastiaan; }
        get peopleJoaquin() { return peopleJoaquin; }
        get peopleIwona() { return peopleIwona; }
        get peopleMatt() { return peopleMatt; }

        get iconBrain() { return iconBrain; }
        get iconCircuit() { return iconCircuit; }
        get iconEnrichment() { return iconEnrichment; }
        get iconPassword() { return iconPassword; }
    }
</script>


<style>
.just {
  text-align: justify;
}

.center {
  text-align: center;
}

</style>
